import myDesskBlack from "@/assets/img/mydessk-black.svg";
import myDesskWhite from "@/assets/img/mydessk-white.svg";
import myDesskReduce from "@/assets/img/mydessk-reduce.svg";
import iconMail from "@/assets/img/icon-mail.svg";
import icoFacturas from "@/assets/img/ico-total-facturas.svg";
import icoCotizaciones from "@/assets/img/ico-total-cotizaciones.svg";
import icoVentas from "@/assets/img/ico-total-ventas.svg";
import icoMore from "@/assets/img/ico-more.svg";
import icoSend from "@/assets/img/ico-send.svg";
import icoPlane from "@/assets/img/ico-plane.svg";
import icoGoogle from "@/assets/img/ico-google.svg";
import icoFacebook from "@/assets/img/ico-facebook.svg";
import icoHey from "@/assets/img/ico-hey.svg";
import imageSign from "@/assets/img/image-sign.png";
import bgFigure from "@/assets/img/bg-figure.svg";
import icoBusiness1 from "@/assets/img/ico_business_1.svg";
import icoBusiness2 from "@/assets/img/ico_business_2.svg";
import icoBusiness3 from "@/assets/img/ico_business_3.svg";
import icoBell from "@/assets/img/ico-bell.svg";
import icoOption from "@/assets/img/ico-option.svg";
import bgDashboard from "@/assets/img/bg-dashboard.jpg";
import bgDashboardPlan from "@/assets/img/bg-dashboard-plan.jpg";
import icoCheck from "@/assets/img/ico-check.svg";
import icoCool from "@/assets/img/ico-cool.svg";
import icoOptionGray from "@/assets/img/ico-option-gray.svg";
import icoWallet from "@/assets/img/ico-wallet.svg";
import icoUserCircle from "@/assets/img/ico-user-circle.svg";
import imgCelebration from "@/assets/img/celebration.svg";
import icoFilter from "@/assets/img/ico-filter.svg";
import icoCirclePlus from "@/assets/img/ico-circle-plus.svg";
import imageQuote from "@/assets/img/image-quote.svg";
import imageDeskConnect from "@/assets/img/image-desk-connect.svg";
import bgOval from "@/assets/img/bg-oval.svg";
import icoCash from "@/assets/img/ico-cash.svg";
import icoCircleArrowRight from "@/assets/img/ico-circle-arrow-right.svg";
import icoCircleChecked from "@/assets/img/ico-circle-checked.svg";
import icoTrash from "@/assets/img/ico-trash.svg";
import icoUserLine from "@/assets/img/ico-user-line.svg";
import bgProfile from "@/assets/img/bg-profile.svg";
import icoCircleStores from "@/assets/img/ico-circle-stores.svg";
import icoStores from "@/assets/img/ico-stores.svg";
import icoPencil from "@/assets/img/ico-pencil.svg";
import icoEmissionPoint from "@/assets/img/ico-emission-point.svg";
import icoCheckMark from "@/assets/img/ico-check-mark.svg";
import icoCheckMarkSuccess from "@/assets/img/ico-check-mark-success.svg";
import icoArrowLeft from "@/assets/img/ico-arrow-left.svg";
import icoRectGreen from "@/assets/img/ico-rect-green.svg";
import icoRectBlue from "@/assets/img/ico-rect-blue.svg";
import icoRectViolet from "@/assets/img/ico-rect-violet.svg";
import icoPlanFree from "@/assets/img/ico-plan-free.svg";
import icoPlanPersonal from "@/assets/img/ico-plan-personal.svg";
import icoPlanBusiness from "@/assets/img/ico-plan-business.svg";
import icoRocket from "@/assets/img/ico-rocket.svg";
import icoBriefcase from "@/assets/img/ico-briefcase.svg";
import icoCircleInfo from "@/assets/img/ico-circle-info.svg";
import icoNotifyDanger from "@/assets/img/ico-notify-danger.svg";
import emptyCategories from "@/assets/img/empty-categories.svg";
import emptyProducts from "@/assets/img/empty-products.svg";
import emptyBusiness from "@/assets/img/empty-business.svg";
import emptyMedia1 from "@/assets/media/empty-media-1.mp4";
import emptyMedia2 from "@/assets/media/empty-media-2.mp4";
import emptyMedia3 from "@/assets/media/empty-media-3.mp4";
import emptyMedia4 from "@/assets/media/empty-media-4.mp4";
import icoCalendario from "@/assets/img/ico-calendario.svg";
import icoWooCommerce from "@/assets/img/ico-woocommerce.svg";
import icoCircleLink from "@/assets/img/ico-circle-link.svg";
import icoSendBlack from "@/assets/img/ico-send-black.svg";
import icoVersions from "@/assets/img/ico-versions.svg";
import icoCobrar from "@/assets/img/ico-cobrar.svg";
import icoInvoice from "@/assets/img/ico-invoice.svg";
import icoSignature from "@/assets/img/ico-signarure.svg";
import icoPreview from "@/assets/img/ico-preview.svg";
import icoPrint from "@/assets/img/ico-print.svg";

import iconCheckOn from "@/assets/img/ico-check-on.svg";
import iconCheckOff from "@/assets/img/ico-check-off.svg";
import iconCheck from "@/assets/img/_icon_check.svg";
import iconDelete from "@/assets/img/_icon_delete.svg";
import iconDisapproved from "@/assets/img/_icon_disapproved.svg";
import iconDownload from "@/assets/img/_icon_download.svg";
import iconEdit from "@/assets/img/_icon_edit.svg";
import iconHistory from "@/assets/img/_icon_history.svg";
import iconNuled from "@/assets/img/_icon_nuled.svg";
import iconPaylink from "@/assets/img/_icon_paylink.svg";
import icoPaylinkNew from "@/assets/img/ico-paylink.svg";
import iconSend from "@/assets/img/_icon_send.svg";
import iconBusiness from "@/assets/img/_icon_business.svg";
import iconConfig from "@/assets/img/_icon_config.svg";
import iconLogout from "@/assets/img/_icon_logout.svg";
import iconPlans from "@/assets/img/_icon_plans.svg";
import iconTeam from "@/assets/img/_icon_team.svg";
import iconChat from "@/assets/img/chat.svg";
import icoDownloadDoc from "@/assets/img/ico-download-doc.svg";


import bgContacts from "@/assets/img/bg-contacts.svg";

export {
  myDesskBlack,
  myDesskWhite,
  myDesskReduce,
  iconCheck,
  iconDelete,
  iconDisapproved,
  iconDownload,
  iconEdit,
  iconHistory,
  iconNuled,
  iconPaylink,
  iconSend,
  iconBusiness,
  iconConfig,
  iconLogout,
  iconPlans,
  iconTeam,
  emptyCategories,
  icoCobrar,
  emptyProducts,
  emptyBusiness,
  bgDashboard,
  bgDashboardPlan,
  icoNotifyDanger,
  icoRectGreen,
  icoRectBlue,
  icoRectViolet,
  icoPlanFree,
  icoPlanPersonal,
  icoPlanBusiness,
  icoRocket,
  icoBriefcase,
  icoCircleInfo,
  icoCheck,
  icoCool,
  icoOptionGray,
  icoWallet,
  iconMail,
  icoFacturas,
  icoCotizaciones,
  icoVentas,
  icoMore,
  icoSend,
  icoPlane,
  icoGoogle,
  icoFacebook,
  icoHey,
  imageSign,
  bgFigure,
  icoBusiness1,
  icoBusiness2,
  icoBusiness3,
  icoBell,
  icoOption,
  icoUserCircle,
  imgCelebration,
  icoFilter,
  icoCirclePlus,
  imageQuote,
  imageDeskConnect,
  bgOval,
  icoCash,
  icoCircleArrowRight,
  icoCircleChecked,
  icoTrash,
  icoUserLine,
  bgProfile,
  icoCircleStores,
  icoStores,
  icoPencil,
  icoEmissionPoint,
  icoCheckMark,
  icoCheckMarkSuccess,
  icoArrowLeft,
  emptyMedia1,
  emptyMedia2,
  emptyMedia3,
  emptyMedia4,
  icoCalendario,
  icoWooCommerce,
  icoCircleLink,
  icoSendBlack,
  icoVersions,
  iconChat,
  iconCheckOn,
  iconCheckOff,
  bgContacts,
  icoSignature,
  icoPreview,
  icoPaylinkNew,
  icoInvoice,
  icoPrint,
  icoDownloadDoc
};
