<template>
  <button
    :disabled="disabled"
    class="btn md__btn-primary"
    @click.prevent="handleClick"
  >
    {{ title }}
  </button>
</template>

<script>
export default {
  name: "ButtonPrimaryComponent",

  methods: {
    handleClick() {
      this.$emit("onSaveClick");
    }
  },
  props: {
    title: {
      default: ""
    },
    disabled: {
      default: false
    }
  }
};
</script>

<style></style>
