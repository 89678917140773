<template>
  <a href="javascript:;" class="font-bold" :class="classNames" @click="onClick">
    <img :src="icoCirclePlus" alt="" width="24" />
    <span class="pl-2" v-if="title && !onlyIcon">{{ title }}</span>
  </a>
</template>

<script>
import { icoCirclePlus } from "@/services/resources";

export default {
  name: "LinkPlusComponent",
  data() {
    return {
      icoCirclePlus
    };
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    }
  },
  props: {
    title: {
      default: "Añadir nuevo"
    },
    classNames: {
      default: "text-color-secondary"
    },
    onlyIcon: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style></style>
